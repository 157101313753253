import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TableActions from '../../TableUI/TableActions'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import BusinessIcon from '@mui/icons-material/Business'
import PreviewIcon from '@mui/icons-material/Preview'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import {
    ACCOUNT_CONFIG__UPDATE,
    ACCOUNT_CONFIG__VIEW,
    BANK_ACCOUNTS__CREATE,
    BANK_ACCOUNTS__DELETE,
    BANK_ACCOUNTS__UPDATE,
    BANK_ACCOUNTS__VIEW,
    COUNTRY_SPREADS__CREATE,
    COUNTRY_SPREADS__DELETE,
    COUNTRY_SPREADS__UPDATE,
    COUNTRY_SPREADS__VIEW,
    MERCHANTS__UPDATE,
    MERCHANT_CONTACTS__CREATE,
    MERCHANT_CONTACTS__DELETE,
    MERCHANT_CONTACTS__UPDATE,
    MERCHANT_CONTACTS__VIEW,
    MERCHANT_PAYMENT_METHODS__UPDATE,
    MERCHANT_PAYMENT_METHODS__VIEW,
    MISCELLANEOUS__CREATE,
    MISCELLANEOUS__DELETE,
    MISCELLANEOUS__UPDATE,
    MISCELLANEOUS__VIEW,
    ADDRESSES__VIEW,
    ADDRESSES__CREATE,
    ADDRESSES__UPDATE,
    ADDRESSES__DELETE
} from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'



export default function MerchantMenu({
    id,
    merchant = null,
    actions = []
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        if (merchant) {
            localStorage.setItem('merchant', JSON.stringify(merchant))
        }

        navigate(view)
    }

    return (
        <TableActions actions={[
            {
                id: `edit-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/edit`) },
                icon: hasPermissions(MERCHANTS__UPDATE) ? <EditIcon /> : <PreviewIcon />,
                title: hasPermissions(MERCHANTS__UPDATE) ? t('Edit') : t('View')
            },
            {
                id: `config-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/config`) },
                icon: <SettingsIcon />,
                title: t('Configurations'),
                show: hasPermissions([ACCOUNT_CONFIG__VIEW, ACCOUNT_CONFIG__UPDATE])
            },
            {
                id: `methods-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/methods`) },
                icon: <MonetizationOnIcon />,
                title: t('Methods & fees'),
                show: hasPermissions([
                    MERCHANT_PAYMENT_METHODS__VIEW, MERCHANT_PAYMENT_METHODS__UPDATE
                ])
            },
            {
                id: `submethods-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/submethods`) },
                icon: <AttachMoneyIcon />,
                title: t('Submethods & fees'),
                // TODO: Add submethods permissions
                show: hasPermissions([
                    MERCHANT_PAYMENT_METHODS__VIEW, MERCHANT_PAYMENT_METHODS__UPDATE
                ])
            },
            {
                id: `bank-accounts-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/bank-accounts`) },
                icon: <AccountBalanceIcon />,
                title: t('Bank accounts'),
                show: hasPermissions([
                    BANK_ACCOUNTS__VIEW, BANK_ACCOUNTS__CREATE, BANK_ACCOUNTS__UPDATE, BANK_ACCOUNTS__DELETE
                ])
            },
            {
                id: `spreads-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/spreads`) },
                icon: <ShowChartIcon />,
                title: t('Spreads'),
                show: hasPermissions([
                    COUNTRY_SPREADS__VIEW, COUNTRY_SPREADS__CREATE, COUNTRY_SPREADS__UPDATE, COUNTRY_SPREADS__DELETE
                ])
            },
            {
                id: `miscellaneous-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/miscellaneous`) },
                icon: <MiscellaneousServicesIcon />,
                title: t('Miscellaneous'),
                show: hasPermissions([
                    MISCELLANEOUS__VIEW, MISCELLANEOUS__CREATE, MISCELLANEOUS__UPDATE, MISCELLANEOUS__DELETE
                ])
            },
            {
                id: `contacts-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/contacts`) },
                icon: <ContactMailIcon />,
                title: t('Contacts'),
                show: hasPermissions([
                    MERCHANT_CONTACTS__VIEW, MERCHANT_CONTACTS__CREATE, MERCHANT_CONTACTS__UPDATE, MERCHANT_CONTACTS__DELETE
                ])
            },
            {
                id: `addresses-${id}`,
                handleClick: () => { handleNavigate(`/merchants/${id}/addresses`) },
                icon: <BusinessIcon />,
                title: t('Addresses'),
                show: hasPermissions([
                    ADDRESSES__VIEW, ADDRESSES__CREATE, ADDRESSES__UPDATE, ADDRESSES__DELETE
                ])
            },
            ...actions
        ]}
        />
    )
}
