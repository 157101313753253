import { GenericList, ExtraCol } from 'components/Common/GenericList'
import TableActions from 'components/TableUI/TableActions'

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { useEffect } from 'react'


export default function MethodsList() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { methodId } = useParams()

    const api = useAxiosPrivate()

    const actions = (entry) => <TableActions actions={[
        {
            id: `edit-${entry.id}`,
            handleClick: () => { navigate(`${entry.id}/fees`) },
            icon: <MonetizationOnIcon />,
            title: t('Fees'),
        }
    ]} />

    const [methodData, setMethodData] = useState(null)

    useEffect(() => {
        api.get(`/internal/payment-methods/${methodId}/`).then((response) => {
            setMethodData(response.data.data)
        }
        )
    }, [methodId, api])

    return <GenericList
        url={`/internal/payment-methods/${methodId}/submethods/`}
        title={(methodData === null ? '' : `${methodData.name} (${methodData.method_provider.name}) / `) + t("Submethods")}
        extraCols={[ExtraCol("", actions)]}
        hiddenFields={new Set(["id"])}
    />
}
