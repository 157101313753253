import { useState } from 'react';

import { useTranslation } from 'react-i18next'

import { TextField, MenuItem, Checkbox } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { useEffect } from 'react';


function SelectField({ choices, ...otherProps }) {
    return <TextField {...otherProps} select={true} fullWidth={true} variant='outlined'>
        {Object.keys(choices).map((item, pos) => {
            return <MenuItem key={pos} value={item}>
                {choices[item]}
            </MenuItem>
        })}
    </TextField>
}


function BooleanField({ ...otherProps }) {
    const { t } = useTranslation()

    const label = t(otherProps.label)

    // transform "true"/"false" to boolean
    function str2Bool(value) {
        switch (value) {
            case "true":
                return true
            case "false":
                return false
            case "":
                return false
            default:
                return value
        }
    }


    const [checked, setChecked] = useState(str2Bool(otherProps.value))


    // make onChange return "true"/"false" instead of boolean
    function onChange(event) {
        const newChecked = event.target.checked
        otherProps.onChange({ target: { value: newChecked } })
        setChecked(newChecked)
    }

    useEffect(() => {
        onChange({ target: { checked: checked } })
    }, [])  // todo: fix missing dependencies warning

    return <Stack direction="column" spacing="-50%" alignItems="center" justifyContent="center">
        <p style={{ fontSize: "90%" }}>{label}</p>
        <Checkbox {...otherProps} onChange={onChange} checked={checked} style={{ color: "darkslateblue" }} size="medium" />
    </Stack>
}


function ValueEntryField({ fieldMetadata, ...otherProps }) {
    const { t } = useTranslation()

    const label = t(fieldMetadata.label)

    switch (fieldMetadata.type) {
        case "choice":
            return <SelectField
                label={label}
                choices={fieldMetadata.choices}
                {...otherProps}
            />
        case "boolean":
            return <BooleanField
                label={label}
                {...otherProps}
            />
        default:
            return <TextField label={label} {...otherProps} />
    }
}

/**
 * Wrap ValueEntryField to handle nullable fields allowing to set them to null
 * by clicking on a button that hides the field, sets the value to null
 * and shows a button to show it again.
 * Only wrap when the field is nullable, this is checked on fieldMetadata.allow_null.
 */
export default function EditField({ fieldMetadata, ...otherProps }) {
    const { t } = useTranslation()

    const [showField, setShowField] = useState(!((otherProps.value === null || otherProps.value === undefined) && fieldMetadata.allow_null))

    function hideField() {
        setShowField(false)
        otherProps.onChange({ target: { value: null } })
    }

    function doShowField() {
        setShowField(true)
        otherProps.onChange({ target: { value: "" } })
    }

    if (otherProps.value === null) {
        otherProps.value = '';
    }

    return (
        <Stack direction="row">
            {showField && fieldMetadata.allow_null &&
                <IconButton onClick={hideField}>
                    <DeleteIcon />
                </IconButton>
            }
            {showField ?
                <ValueEntryField fieldMetadata={fieldMetadata} {...otherProps} fullWidth />
                :
                <><IconButton onClick={doShowField}>
                    <AddIcon />
                </IconButton>
                    <p style={{ opacity: 0.5 }}>{t(fieldMetadata.label)}</p>
                </>
            }
        </Stack>
    )
}
