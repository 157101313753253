import React, { useState, useEffect } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import PageTitle from '../../../Misc/PageTitle'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { replaceNullValues, removeNullValues } from '../../../../services/utils'
import { toast } from 'react-toastify';
import AccountConfigForm from './AccountConfigForm';
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'


const nullableValues = new Set([
  "countries",
])


export default function AccountConfig() {

  const { t } = useTranslation()

  const navigate = useNavigate()

  const isMounted = useIsMounted()

  const api = useAxiosPrivate()

  const { userId } = useParams()

  const [loading, setLoading] = useState(true)

  const [config, setConfig] = useState()

  const handleCancel = () => {
    navigate('/merchants')
  }

  const handleSubmit = (values) => {
    api.patch(`/internal/merchants/${userId}/account-config/`, removeNullValues(values, nullableValues))
      .then(() => isMounted() && toast.success(t('The information has been updated')))
      .catch(() => isMounted() && toast.error(t('Something went wrong')))
  }

  const FORM_VALIDATION = Yup.object().shape({
    language: Yup.string()
      .required(t('This field is required'))
      .min(2, t('Invalid language code'))
      .max(2, t('Invalid language code')),
    settlement_fee: Yup.number()
      .required(t('This field is required')),
    chargebacks_fee: Yup.number()
      .required(t('This field is required')),
    refunds_fee: Yup.number()
      .required(t('This field is required')),
    minimum_payout_amount: Yup.number()
      .required(t('This field is required')),
    waiting_time: Yup.number()
      .required(t('This field is required')),
    // 
    countries: Yup.string()
      .max(255, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 255 }))
      .matches("^[A-Z]{2}(?:,[A-Z]{2})*$", t("Should be comma separated uppercase country codes"))
      .nullable()
      .transform((value, originalValue) => value === '' ? undefined : value)
      .defined(t('To allow all countries, click the delete button, to allow None disable the merchant')),
  })

  useEffect(() => {
    const getConfig = () => {
      api.get(`/internal/merchants/${userId}/account-config/`)
        .then(({ data: { data } }) => {
          if (isMounted()) {
            data.language = String(data.language).toUpperCase()
            data.settlement_currency = String(data.settlement_currency).toUpperCase()
            data.waiting_time = 1
            setConfig(replaceNullValues(data, nullableValues))
          }
        })
        .catch(() => isMounted() && toast.error(t('Something went wrong')))
        .finally(() => isMounted() && setLoading(false))
    }

    getConfig()
  }, [t, userId, isMounted, api])

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <PageTitle title={t('Update merchant information')} />

            <Hidden xlUp={true}>
              {userId ? <MerchantMenu id={userId} /> : ''}
            </Hidden>
          </Stack>

          <MerchantAlias />

          <Grid container spacing={2}>
            <Grid item xs={12} xl={2}>
              <MerchantSubmenu current='settings' id={userId} />
            </Grid>
            <Grid item xs={12} xl={10}>
              {
                loading ? <FormSkeletonWrapper /> :
                  <AccountConfigForm
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                    initialFormState={config}
                    FORM_VALIDATION={FORM_VALIDATION}
                  />
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
