import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableBackdrop from '../../TableUI/TableBackdrop'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import CurrencyValue from '../../TableUI/CurrencyValue'
import DatetimeValue from '../../TableUI/DatetimeValue'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import WithdrawalModal from './WithdrawalModal'
import { toast } from 'react-toastify'
import { parseErrors } from '../../../services/utils'
import useIsMounted from '../../../hooks/useIsMounted'
import { PM_BANK, PM_PAYPAL } from '../../../enums/Merchants'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import WithdrawalDelete from './WithdrawalDelete';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { WITHDRAWALS__COMPLETE, WITHDRAWALS__DELETE } from '../../../enums/Caps'


export default function WithdrawalsTable({
    data,
    setData,
    loading = false
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [open, setOpen] = useState({
        confirm: false,
        delete: false
    })

    const [selectedElement, setSelectedElement] = useState(null)

    const [confirming, setConfirming] = useState(false)

    const handleOpen = (element, action) => {
        setSelectedElement(element)
        setOpen({
            ...open, [action]: true
        })
    }

    const handleClose = (action) => {
        setSelectedElement(null)
        setOpen({
            ...open, [action]: false
        })
    }

    const handleRemove = () => {
        if (!selectedElement) {
            toast.error(t('Something went wrong'))
            return
        }
        setConfirming(true)

        api.delete(`/internal/withdrawals/${selectedElement.id}/`)
            .then(() => {
                if (isMounted()) {
                    setData(data.filter(d => d.id !== selectedElement.id))
                    toast.success(t('Payout has been deleted'))
                    handleClose('delete')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
            .finally(() => isMounted() && setConfirming(false))
    }

    const handleConfirm = (id) => {
        setConfirming(true)

        api.post(`/internal/withdrawals/${id}/complete/`)
            .then(() => {
                if (isMounted()) {
                    setData(data.filter(d => d.id !== id))
                    toast.success(t('Payout has been confirmed'))
                    handleClose('confirm')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
            .finally(() => isMounted() && setConfirming(false))
    }

    // md+width: calc(100vw - 300px)

    return (
        <>
            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 100px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Merchant')}</TableCell>
                                <TableCell align="center">{t('Request date')}</TableCell>
                                <TableCell>{t('Bank info')}</TableCell>
                                <TableCell align="right">{t('Total')}</TableCell>
                                <TableCell align="right">{t('Withdrawable amount')}</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? data.map((row, idx) => (
                                <TableRow
                                    key={idx}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.merchant.username}
                                    </TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell>
                                        {(row.merchant?.account_config?.payout_method === PM_BANK && row.merchant.default_bank_account) && (<>
                                            <Typography>{row.merchant.default_bank_account?.bank_country}</Typography>
                                            <Typography>{row.merchant.default_bank_account?.bank_name}</Typography>
                                            <Typography>{row.merchant.default_bank_account?.account_owner}</Typography>
                                            <Typography>{row.merchant.default_bank_account?.account_number}</Typography>
                                        </>)}

                                        {row.merchant?.account_config?.payout_method === PM_PAYPAL && (
                                            <>
                                                <Typography>Paypal</Typography>
                                                <Typography>{row.merchant?.account_config?.paypal_account}</Typography>
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue
                                            amount={row.total_payments}
                                            currency={row.currency}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue
                                            amount={row.withdrawable_amount}
                                            currency={row.currency}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {hasPermissions(WITHDRAWALS__COMPLETE) ? (<IconButton
                                            color="primary"
                                            aria-label={t('Confirm payout')}
                                            onClick={() => { handleOpen(row, 'confirm') }}
                                            title={t('Confirm payout')}
                                            component="span">
                                            <CreditScoreIcon />
                                        </IconButton>) : ''}

                                        {hasPermissions(WITHDRAWALS__DELETE) ? (<IconButton
                                            color="primary"
                                            aria-label={t('Delete payout')}
                                            onClick={() => { handleOpen(row, 'delete') }}
                                            title={t('Delete payout')}
                                            component="span">
                                            <HighlightOffIcon />
                                        </IconButton>) : ''}
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <WithdrawalDelete
                open={open.delete}
                handleClose={handleClose}
                handleConfirm={handleRemove}
                confirming={confirming}
            />

            {selectedElement ? <WithdrawalModal
                handleConfirm={handleConfirm}
                handleClose={() => handleClose('confirm')}
                open={open.confirm}
                withdrawal={selectedElement}
                confirming={confirming}
            /> : ''}
        </>
    )
}
