import { Box, CircularProgress } from '@mui/material'

export default function AmountBox({
    title,
    amount,
    fractionDigits = 2,
    loading = false
}) {
    return (
        <Box
            sx={{
                bgcolor: '#0B1F3F',
                color: '#ffffff',
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
            }}
        >
            <Box>{title}</Box>
            <Box sx={{
                fontSize: {
                    xs: 20,
                    sm: 30
                }, fontWeight: 'medium'
            }}>
                {loading && (<CircularProgress color="inherit" />)}
                {!loading && (new Intl.NumberFormat('es-CL', { maximumFractionDigits: fractionDigits }).format(amount))}
            </Box>
        </Box>
    )
}
