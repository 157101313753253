import { useState, useEffect, useCallback } from 'react'
import { Card, CardContent, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import WithdrawalsTable from './WithdrawalsTable'
import { toast } from 'react-toastify'
import useIsMounted from '../../../hooks/useIsMounted'
import AmountBox from '../../TableUI/AmountBox'
import PageTitle from '../../Misc/PageTitle'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'


export default function WithdrawalsList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [total, setTotal] = useState({
        usd: 0,
        clp: 0,
        eur: 0
    })

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({})

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        setData([])

        api.get('/internal/withdrawals/in-process/')
            .then(({ data: { data, meta } }) => {
                if (isMounted()) {
                    const usd = data.filter(a => a.currency === 'USD').reduce((a, { withdrawable_amount }) => a + withdrawable_amount, 0)
                    const eur = data.filter(a => a.currency === 'EUR').reduce((a, { withdrawable_amount }) => a + withdrawable_amount, 0)
                    const clp = data.filter(a => a.currency === 'CLP').reduce((a, { withdrawable_amount }) => a + withdrawable_amount, 0)

                    setTotal({
                        ...total, clp: clp, usd: usd, eur: eur
                    })

                    setMeta(meta)
                    setData(data)
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, isMounted, api])

    useEffect(() => {
        const init = () => {
            setLoading(true)
            retrieveData()
        }

        init()
    }, [retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Pending payouts')} />

                    <Grid container spacing={2} sx={{ mt: 1, p: 2 }}>
                        <Grid item xs={12} md={4} lg={3}>
                            <AmountBox amount={total.clp} title='CLP' loading={loading} fractionDigits={0} />
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <AmountBox amount={total.usd} title='USD' loading={loading} />
                        </Grid>

                        <Grid item xs={12} md={4} lg={3}>
                            <AmountBox amount={total.eur} title='EUR' loading={loading} />
                        </Grid>
                    </Grid>

                    <WithdrawalsTable
                        data={data}
                        setData={setData}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                    />
                </CardContent>
            </Card>
        </>
    )
}
