import { useState, useEffect } from 'react'
import { Card, CardContent, Grid, Stack } from '@mui/material'
import React from 'react'
import PageTitle from '../../../Misc/PageTitle'
import { useTranslation } from 'react-i18next'
import AppFilters from '../../../AppFilters'
import Filters from './Filters'
import { substractDays } from '../../../../services/utils'
import BackdropWrapper from '../../../BackdropWrapper/BackdropWrapper'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { ST_COMPLETED } from '../../../../enums/Transactions'


export default function Sales() {
    /**
     * Para no mostrar warning por versiones de google charts
     */
    const originalWarn = console.warn;

    console.warn = function (...args) {
        const arg = args && args[0];

        if (arg && arg.includes('Attempting to load version \'51\' of Google Charts')) return;

        originalWarn(...args);
    }
    /**
     * End
     */

    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const today = new Date()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({
        date__gte: new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(substractDays(30)),
        date__lte: new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(today),
        group_by: ["status", "date"],
    })

    const [data, setData] = useState([])

    const [total, setTotal] = useState({
        count: 0,
        amount: 0
    })

    const handleOnSearch = (values) => {
        setParams({ ...values, group_by: ["status", values.group_by] })
    }

    useEffect(() => {
        const retrieveData = () => {
            setLoading(true)

            api.get('/internal/analytics/traffic/', { params: params })
                .then(({ data: { data: { aggregations, data } } }) => {
                    if (isMounted()) {
                        const new_data = data.filter(d => d.status === ST_COMPLETED)
                            .map(d => d.data)
                            .map(d => {
                                return d.map(dt => ({
                                    name: params.group_by[1] === "month" ? dt.month : dt.date,
                                    sales: dt.aggregations.amount
                                }))
                            })

                        const _total = new_data[0].reduce((a, { sales }) => a + sales, 0);

                        setTotal({ ...total, amount: _total })
                        setData(new_data[0])
                    }
                })
                .finally(() => isMounted() && setLoading(false))
        }

        retrieveData()
        // eslint-disable-next-line
    }, [params, api, isMounted])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Sales stats')} />

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} loading={loading} />} open={true} />

                    {loading && (<Stack spacing={2} direction="row" mt={4} justifyContent="center">
                        <BackdropWrapper open={loading} />
                    </Stack>)}

                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{
                            height: '300px'
                        }}>
                            {!loading && (<Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    mr: 4
                                }}
                            >Total ventas en periodo {new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(total.amount)}</Stack>)}

                            {!loading && (<ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={500}
                                    height={400}
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip
                                        formatter={(value, name, props) => [
                                            new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value),
                                            "Ventas"
                                        ]}
                                    />
                                    <Legend verticalAlign="top" height={36} />
                                    <Line
                                        type="monotone"
                                        dataKey="sales"
                                        name={`Volumen de ventas (EUR) / ${params.date__gte} - ${params.date__lte}`}
                                        stroke="#8884d8"
                                        activeDot={{ r: 8 }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>)}
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
