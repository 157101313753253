import { Card, CardContent, Stack } from '@mui/material'
import { GenericList } from 'components/Common/GenericList'
// import TableActions from 'components/TableUI/TableActions'

import { useParams } from 'react-router-dom'
import MerchantSubmenu from '../MerchantSubmenu'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
import MerchantAlias from '../MerchantAlias'


export default function MerchantSubmethodsList() {
    const { t } = useTranslation()

    const { userId } = useParams()

    return <Card><CardContent><MerchantAlias /><Stack direction="row"><MerchantSubmenu current='submethods-and-fees' id={userId} /><GenericList
        url={`/internal/merchants/${userId}/submethods/`}
        title={t("Submethods merchant fees")}
        // editableFields={new Set(["name"])}
        hiddenFields={new Set(["id", "status_"])}
        translatedChoiceFields={new Set(["status"])}
    /></Stack></CardContent></Card>
}
